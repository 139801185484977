var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "videoMonitorEdit__wrapper" }, [
    _c("div", { staticClass: "breadcrumb" }),
    _c("div", { staticClass: "content" }, [
      _c("h2", { staticClass: "title" }, [
        _c("div", { staticClass: "title_icon" }),
        _vm._v(
          " " + _vm._s(_vm.isEdit ? "编辑监控设备" : "添加监控设备") + " "
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "wrapper",
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              $event.preventDefault()
              return _vm.submitData.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { width: "300px", float: "left" },
              attrs: {
                "label-position": "right",
                "label-width": "100px",
                model: _vm.formInline,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.Equipment_name"),
                    prop: "monitorEquipmentChannelName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: 50, placeholder: "请输入设备名称" },
                    model: {
                      value: _vm.formInline.monitorEquipmentChannelName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "monitorEquipmentChannelName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.monitorEquipmentChannelName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.Belonging_NVR"),
                    prop: "monitorEquipmentId",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", placeholder: "请选择所属NVR" },
                      on: { change: _vm.NVRChange },
                      model: {
                        value: _vm.formInline.monitorEquipmentId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "monitorEquipmentId", $$v)
                        },
                        expression: "formInline.monitorEquipmentId",
                      },
                    },
                    _vm._l(_vm.NVRList, function (value) {
                      return _c("el-option", {
                        key: value.monitorEquipmentId,
                        attrs: {
                          label: value.monitorEquipmentName,
                          value: value.monitorEquipmentId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.netType == 0
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "设备号",
                        prop: "monitorEquipmentChannelCode",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: 50, placeholder: "请输入设备号" },
                        model: {
                          value: _vm.formInline.monitorEquipmentChannelCode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formInline,
                              "monitorEquipmentChannelCode",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formInline.monitorEquipmentChannelCode",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.netType == 1
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "通道编码",
                        prop: "monitorEquipmentChannelCode",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入",
                          maxlength: "32",
                          oninput: "value=value.replace(/[^\\d]/g,'')",
                        },
                        model: {
                          value: _vm.formInline.monitorEquipmentChannelCode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formInline,
                              "monitorEquipmentChannelCode",
                              $$v
                            )
                          },
                          expression: "formInline.monitorEquipmentChannelCode",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "800px" },
                  attrs: { label: "泊位", prop: "berthId" },
                },
                [
                  _c("el-transfer", {
                    attrs: {
                      filterable: "",
                      "filter-method": _vm.filterMethod,
                      titles: ["未选择", "已选择"],
                      "filter-placeholder": "请输入泊位",
                      props: {
                        key: "berthId",
                        label: "berthCode",
                      },
                      data: _vm.berthIdList,
                    },
                    on: { change: _vm.berthChange },
                    model: {
                      value: _vm.berthIds,
                      callback: function ($$v) {
                        _vm.berthIds = $$v
                      },
                      expression: "berthIds",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "text-align": "left", "margin-left": "10%" } },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "88px" },
              attrs: { type: "primary" },
              on: { click: _vm.submitData },
            },
            [_vm._v(_vm._s(_vm.$t("button.preservation")))]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "88px" },
              attrs: { type: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }